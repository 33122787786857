.select-block {
  position: relative;
  /* display: flex; */
  align-items: center;
}

.select-block + .select-block {
  margin-top: 1.4rem;
}

.row .select-block + .input-block {
  margin-left: 1.4rem;
}

.row .input-block + .select-block {
  margin-left: 1.4rem;
}

.row .select-block + .select-block {
  margin: unset;
  margin-left: 1.4rem;
}

.select-block label {
  font-size: 1.4rem;
}

.select-block select {
  width: 100%;
  height: 5.6rem;
  margin-top: 0.8rem;
  border-radius: 0.8rem;
  background: var(--color-input-background);
  border: 1px solid var(--color-line-in-white);
  outline: 0;
  padding: 0 2.6rem;
  font: 1.6rem Archivo;
}

.select-block select:invalid {
  color: red;
  opacity: 0.7;
  border-color: red;
}

.select-block:focus-within::after {
  width: calc(100% - 3.2rem);
  height: 2px;
  content: '  ';
  background: var(--color-primary-light);
  position: absolute;
  left: 1.6rem;
  right: 1.6rem;
  bottom: 0;
}

.Blink select {
  background-color: var(--color-secundary-dark);
  transition: ease-in-out 0.2s;
}
