#pageHeader {
  grid-area: header;
}

#pageHeader header {
  height: 100%;
  background: var(--color-primary);
}

#pageHeader header .header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7.5rem;
}

#pageHeader header .logo {
  /* background: var(--color-primary); */
  height: 100%;
  /* width: 50rem; */
  display: none;
  align-items: center;
  justify-content: center;
}

#pageHeader header .logo img {
  width: 24rem;
}

@media (max-width: 1400px) {
}

@media (max-width: 576px) {
  #pageHeader header .logo {
    display: flex;
  }

  /*#pageHeader header {
    height: 5rem;
  } */
  /* #pageHeader header .logo img {
    width: 14rem;
    margin-top: 0.6rem;
  } */
}
