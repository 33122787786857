.textarea-block {
  position: relative;
}

.textarea-block + .textarea-block {
  margin-top: 1.4rem;
}

.textarea-block label {
  font-size: 1.4rem;
}

.textarea-block textarea {
  width: 100%;
  height: 20rem;
  margin-top: 0.8rem;
  border-radius: 0.8rem;
  background: var(--color-textarea-background);
  border: 1px solid var(--color-line-in-white);
  outline: 0;
  padding: 0 1.6rem;
  font: 1.6rem Archivo;
}

.textarea-block:focus-within::after {
  width: calc(100% - 3.2rem);
  height: 2px;
  content: '  ';
  background: var(--color-primary-light);
  position: absolute;
  left: 1.6rem;
  right: 1.6rem;
  bottom: 0;
}
