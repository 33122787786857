.input-block {
  position: relative;
}

.input-block + .input-block {
  margin-top: 1.4rem;
}

.input-block label {
  font-size: 1.4rem;
}

.input-block input:not([type='checkbox']) {
  width: 100%;
  height: 5.6rem;
  margin-top: 0.8rem;
  border-radius: 0.8rem;
  background: var(--color-input-background);
  border: 1px solid var(--color-line-in-white);
  outline: 0;
  padding: 0 1.6rem;
  font: 1.6rem Archivo;
}

.input-block input:invalid {
  color: red;
  opacity: 0.7;
  border-color: red;
}

.input-block input:disabled {
  color: -internal-light-dark(graytext, rgb(170, 170, 170));
  opacity: 0.7;
  border-color: rgba(118, 118, 118, 0.3);
}

.input-block:not(.checkbox-block):focus-within::after {
  width: calc(100% - 3.2rem);
  height: 2px;
  content: '  ';
  background: var(--color-primary-light);
  position: absolute;
  left: 1.6rem;
  right: 1.6rem;
  bottom: 0;
}
