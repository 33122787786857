.table-block table {
  width: 100%;
}

.table-block table tr {
  /* text-align: left; */
  /* vertical-align: center; */
  height: 4rem;
  align-items: left;
}

.table-block table thead tr td {
  font: 700 1.6rem Poppins;
  padding: 0.75rem;
}

.table-block table tbody tr td {
  padding: 0.75rem;
  font: 500 1.6rem Poppins;
  border-top: 1px solid #dee2e6;
  align-items: left;
}

.divHide {
  visibility: hidden;
  max-height: 0;
}

.table-block table .buttons-grid {
  display: flex;
  justify-content: space-evenly;
}

.table-block .green {
  color: green;
}

.table-block .red {
  color: red;
}

.table-block .buttons-grid a {
  cursor: pointer;
  border-radius: 0.8rem;
  font: 400 1.2rem Archivo;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;

  text-decoration: none;
  color: var(--color-button-text);
  background: var(--color-primary-lighter);

  transition: background-color 0.4s;
}
