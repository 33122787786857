#page-login {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;

  /* align-items: center; */

  padding-top: 2rem;

  /* color: var(--color-text-in-primary); */
  background: linear-gradient(
      180deg,
      var(--color-primary) 0%,
      var(--color-secondary) 100%
    ),
    rgba(197, 197, 197, 0.55);
  /* background: var(--color-primary); */
}

#page-login .login-box {
  background: var(--color-box-base);
  width: 58.4rem;
  height: 56.9rem;
  border-radius: 0.8rem;
}

#page-login .login-box-body {
  padding: 3rem;
}

#page-login .login-box-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}
#page-login .login-box-header p {
  font: 700 2.9rem Poppins;
}

#page-login .input-block + .input-block {
  margin: 0rem;
}

#page-login .input-block input {
  margin-top: 0rem;
}

#page-login .input-block:nth-child(1) input {
  border-radius: 0.8rem 0.8rem 0 0;
}

#page-login .input-block:nth-child(2) input {
  border-top: 0;
  border-radius: 0 0 0.8rem 0.8rem;
}

.login-box .login-box-footer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.login-box .login-box-footer .controls {
  font: 500 1.4rem Poppins;
  width: 100%;
  /* padding: 0rem 3rem; */
  display: flex;
  justify-content: center;
  margin: 0rem 0rem 2rem 0rem;
}

.login-box .login-box-footer .controls label span {
  margin-bottom: 1rem;
}

.login-box .login-box-footer button {
  font: 700 1.6rem Archivo;
  width: 35.2rem;
  height: 5.6rem;
  border: none;
  cursor: pointer;
  background-color: var(--color-secondary);
  border-radius: 0.8rem;
  color: var(--color-title-in-primary);
}

@media (max-width: 576px) {
  #page-login .login-box {
    width: 100%;
  }
  #page-login .login-box-header img {
    width: 20rem;
  }

  #page-login .login-box-header p {
    font: 700 2rem Poppins;
  }

  #page-login .login-box-body {
    padding: 3rem 1rem 0rem;
  }

  #page-login .content {
    width: 100%;
  }

  .login-box .login-box-footer .controls {
    padding: 0rem 1rem;
  }
}
