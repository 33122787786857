#page-financeiro {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template:
    'sidenav header' 7.5rem
    'sidenav content' 1fr
    / 30rem 1fr;
}

#page-financeiro .bloco {
  display: revert;
  /* width: 100%; */
  align-items: left;
  background: var(--color-box-base);
  margin: 1rem;
  border-radius: 0.8rem;
  padding: 1rem;
}

#page-financeiro .bloco-input {
  display: inline;
  width: 100%;
  align-items: left;
  background-color: red;
  /* background: var(--color-box-base); */
  margin: 1rem;
  border-radius: 0.8rem;
  padding: 1rem;
}

#page-financeiro .content {
  /* background: var(--color-box-base); */
  padding: 1rem;
  /* width: 100vw; */
  border-radius: 0.8rem;
}

#page-financeiro .content table tr {
  text-align: left;
  vertical-align: center;
  height: 4rem;
  /* margin-bottom: 1px; */
  /* border-bottom: 1px solid black; */
}

#page-financeiro .content table tr td {
  padding: 0.75rem;
  font: 500 1.6rem Poppins;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  align-items: initial;
}

#page-financeiro .buttons-container {
  display: flex;
  justify-content: center;
  /* margin: 3.2rem 0; */
}

#page-financeiro .resultado {
  padding: 1rem;
  overflow: auto;
  height: 20rem;
  width: 100%;
}

#page-financeiro .resultado :hover {
  background: var(--color-background);
}

#page-financeiro .titulo-grupo {
  margin-top: 25px;
}

#page-financeiro .nao-vincular {
  background-color: var(--color-background);
}

#page-financeiro .buttons-grid.nao-vincular a {
  background-color: var(--color-background);
  display: none;
}

#page-financeiro .table-default {
  width: 100%;
}

#page-financeiro .buttons-container a {
  /* min-width: 8rem;
  height: 4rem; */
  cursor: pointer;
  border-radius: 0.8rem;
  font: 700 1.6rem Archivo;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  padding: 1rem;
  text-decoration: none;
  color: var(--color-button-text);

  background: var(--color-primary-lighter);
  transition: background-color 0.4s;
}

#page-financeiro .buttons-container a:first-child {
  margin-right: 1.6rem;
}

#page-financeiro .buttons-container.commargem {
  margin: 1.6rem;
}

#page-financeiro .buttons-container a:hover {
  background: var(--color-primary-dark);
}

#page-financeiro .buttons-grid {
  display: inline-flex;
  justify-content: center;
  align-content: center;
}

#page-financeiro .buttons-grid a {
  cursor: pointer;
  border-radius: 0.8rem;
  font: 400 1.2rem Archivo;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;

  text-decoration: none;
  color: var(--color-button-text);
  background: var(--color-primary-lighter);

  transition: background-color 0.4s;
}

.svg-inline--fa {
  margin-right: 1rem;
  margin-left: 1rem;
}

#page-financeiro .buttons-grid a:hover {
  background: var(--color-primary-dark);
}

#page-financeiro .grid {
  display: grid;
  grid-template-columns: 20% 20% 60%;
}

#page-financeiro .content.left {
  justify-self: left;
  margin: 5px;
}

#page-financeiro .filters {
  display: flex;
  align-items: left;
  justify-content: left;
  background: var(--color-box-base);
  /* height: 5.8rem; */
  padding: 12px;
  border-radius: 0.8rem;
  width: 83vw;
  margin: 1rem;
}

#page-financeiro #filtro {
  height: 3.8rem;
  margin-left: 2rem;
  width: 35rem;
}

#page-financeiro .controls {
  margin-top: 1rem;
  border-top: 1px solid #cccccc;
  /* width: 100%; */
  padding: 2rem;
  background: var(--color-box-base);
}

#page-financeiro label {
  min-width: 30%;
  margin-left: 13px;
}

#page-financeiro .file-block {
  margin-top: 1.6rem;
}

#page-financeiro .bloqueado {
  display: none;
}

#page-financeiro .avatar .image {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border: 0.1rem solid black;
  border-radius: 100%;
  overflow: hidden;
  width: 120px;
  height: 120px;
}

#page-financeiro .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 7.5rem;
}

#page-financeiro .avatarLista {
  /* border-radius: 100%; */
  overflow: hidden;
  width: 50px;
  height: 50px;
}

#page-financeiro .avatarLista img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

@media (max-width: 1400px) {
  #page-financeiro {
    grid-template:
      'sidenav header' 5rem
      'sidenav content' 1fr
      / 30rem 1fr;
  }
}
