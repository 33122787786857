.input-file-block {
  position: relative;
}

.input-file-block + .input-block {
  margin-top: 1.4rem;
}

.input-file-block label {
  font-size: 1.4rem;
}

.input-file-block input {
  display: none;
}
.input-file-block .input {
  width: 100%;
  height: 5.6rem;
  margin-top: 0.8rem;
  border-radius: 0.8rem;
  background: var(--color-input-background);
  border: 1px solid var(--color-line-in-white);
  outline: 0;
  padding: 0 1.6rem;
  font: 1.6rem Archivo;
  display: flex;
  align-items: center;
}

.input-file-block .input .icon {
  font-size: 2rem;
  margin-right: 2rem;
  cursor: pointer;
}

.input-file-block .input .filename {
  font-size: 1.6rem;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.input-file-block:not(.checkbox-block):focus-within::after {
  width: calc(100% - 3.2rem);
  height: 2px;
  content: '  ';
  background: var(--color-primary-light);
  position: absolute;
  left: 1.6rem;
  right: 1.6rem;
  bottom: 0;
}
