#sidebar {
  grid-area: sidenav;
  transition: all 0.5s ease;
  background-color: var(--color-box-base);
  border-right: 1px solid #cccccc;
}

#sidebar .logo {
  background: var(--color-primary);
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#sidebar .logo img {
  width: 14rem;
}

#sidebar nav {
  width: 29.9rem;
  /* height: 100%; */
  /* background: var(--color-box-base); */
  /* border-right: 1px solid #cccccc; */
  transition: width 1s;
}

#sidebar nav .profile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3.8rem;
  border-bottom: 1px solid #cccccc;
}

#sidebar nav .profile a {
  cursor: pointer;
}

#sidebar nav .profile .image {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  overflow: hidden;
  width: 120px;
  height: 120px;
}

#sidebar nav .profile img {
  max-width: 100%;
  height: auto;
  width: 100%;
  height: 100%;
  /* border-radius: 7.5rem; */
  /* width: 13rem; */
}

#sidebar nav .profile p {
  font: 600 1.8rem Poppins;
  padding: 1rem;
}

#sidebar nav .navigation ul li {
  border-bottom: 1px solid #cccccc;
  height: 5rem;
  padding-left: 2rem;
  padding-top: 1rem;
}

#sidebar nav .navigation ul li a {
  font: 500 1.8rem Poppins;
  text-decoration: none;
  /* color: var(--color-text-in-primary); */
  color: var(--color-text-base);
}

@media (max-width: 1400px) {
  #sidebar nav {
    width: 25rem;
  }

  #sidebar .logo {
    height: 5rem;
  }

  #sidebar .logo img {
    width: 10rem;
  }
}

@media (max-width: 576px) {
  #sidebar {
    display: none;
    /* width: 1rem; */
  }
}
