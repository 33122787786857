.Box1 {
  width: 400px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  gap: 25px;
}
.Button99 {
  width: 150px;
  height: 50px;
  margin-top: 40px;
  /* background-color: rgb(137, 236, 38); */
  /* border: rgb(137, 236, 38); */
  font-size: 20px;
}

.Check1 {
  display: flex;
}
