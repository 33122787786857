#page-home {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template:
    'sidenav header' 7.5rem
    'sidenav content' 1fr
    / 30rem 1fr;
}

#page-home .home-chart {
  width: 100%;
  height: 30rem;
  background-color: var(--color-box-base);
  padding: 2rem;
}

#page-home .periodoAtual-chart {
  width: 100%;
  display: flex;
  margin-bottom: 4rem;
}

#page-home .info-box {
  padding-top: 2.4rem;
  padding-left: 2.4rem;

  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 100%;
}

#page-home .info-box-body {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  overflow: visible;
  padding-bottom: 16px;
}

#page-home .info-box-content {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  opacity: 1;
  background: transparent;
  color: rgb(52, 71, 103);
  box-shadow: none;
}

#page-home .info-box-icon {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  margin-top: -24px;
  opacity: 1;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem,
    rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}

#page-home .info-box-text {
  text-align: right;
  line-height: 1.25;
  opacity: 1;
  background: transparent;
  color: rgb(52, 71, 103);
  box-shadow: none;
}

#page-home .titulos-box {
  padding: 1rem;
  border-radius: 0.8rem;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  background-color: var(--color-box-base);
}

#page-home .titulos-box h3 {
  margin-bottom: 2%;
}

@media (max-width: 1400px) {
  #page-home {
    grid-template:
      'sidenav header' 5rem
      'sidenav content' 1fr
      / 25rem 1fr;
  }

  #page-home .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .noticias {
    height: 580px;
  }
}

@media (max-width: 576px) {
  #page-home {
    display: flex;
    flex-direction: column;

    /* grid-template:
      "header header" 2rem
      "sidenav content" 1fr
      / 20rem 1fr; */
  }

  .noticias {
    height: 100vh;
  }
}
