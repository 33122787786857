.dkxbutton a {
  /* width: 10rem; */
  height: 4rem;
  padding: 1.6rem;
  cursor: pointer;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--color-button-text);
  transition: background-color 0.2s;
  background-color: var(--color-secondary);
}

.dkxbutton {
  margin: 0.4rem;
}

.dkxbutton a:hover {
  background-color: var(--color-primary);
}
