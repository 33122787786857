#page-chips .table-chips .statusChip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  border-radius: 1rem;
}

#page-chips .table-chips .statusChip.Ativo {
  background-color: green;
}

#page-chips .table-chips .statusChip.Inativo {
  background-color: #a1002e;
}

.table-chips .statusChip span {
  color: white;
}

#page-chip-edit .button-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}
