:root {
  font-size: 60%;

  --color-background: #f0f0f7;
  --color-primary-lighter: #556470;
  --color-primary-light: rgb(115, 120, 150);
  --color-primary: #585570;
  --color-primary-dark: #464170;
  --color-primary-darker: #2d2470;
  --color-secondary: #00afef;
  --color-secundary-dark: #0281af;
  --color-title-in-primary: #ffffff;
  --color-text-in-primary: #a9abae;
  --color-text-title: #32264d;
  --color-text-complement: #9c98a6;
  --color-text-base: #6a6180;
  --color-line-in-white: #e6e6f0;
  --color-input-background: #f8f8fc;
  --color-button-text: #ffffff;
  --color-box-base: #ffffff;
  --color-box-footer: #fafafc;
}

div[id^='page-'] {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template:
    'sidenav header' 7.5rem
    'sidenav content' 1fr
    / 30rem 1fr;
}

.ReactModal__Overlay {
  z-index: 1000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100vh;
}

body {
  background: var(--color-background);
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
}

body,
input,
button,
textarea {
  font: 500 1.6rem Poppins;
  color: var(--color-text-base);
}

.container {
  padding: 1rem;
  grid-area: content;
}

.bloco {
  display: revert;
  /* width: 100%; */
  align-items: left;
  background: var(--color-box-base);
  margin: 1rem;
  border-radius: 0.8rem;
  padding: 1rem;
}

.row {
  display: flex;
}

.row .input-block + .input-block {
  margin: unset;
  margin-left: 1.4rem;
}

#modalGuias .videoContent {
  width: 500px;
  height: 375px;
  margin-bottom: 1rem;
}

#modalGuias .controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  justify-content: center;
}

#modalGuias .controls a.btnCancelar {
  background: #dc3545;
}

#modalGuias .controls a:first-child {
  margin-right: 1.6rem;
}

#modalGuias .controls a {
  width: 11rem;
  height: 4rem;
  cursor: pointer;
  border-radius: 0.8rem;
  font: 700 1.6rem Archivo;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  text-decoration: none;
  color: var(--color-button-text);

  transition: background-color 0.2s;
}

#modalGuias .videoLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 5rem;
}

#modalGuias .controls a {
  background: var(--color-primary-lighter);
}

#modalGuias .controls a:hover {
  background: var(--color-primary-dark);
}

#modalGuias .controls a.btnCancelar:hover {
  background: #c82333;
}

.userDialog {
  background-color: #fafafa;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  border-radius: 5%;
  margin-top: 15%;
  margin-left: 35%;
  width: 30%;
  box-shadow: 15px 15px 15px rgba(11, 82, 17, 0.5);
}

.userDialog .controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  justify-content: center;
}

.userDialog .titulo {
  display: flex;
  justify-content: center;
  padding: 3rem;
}

.userDialog .controls a {
  width: 11rem;
  height: 4rem;
  cursor: pointer;
  border-radius: 0.8rem;
  font: 700 1.6rem Archivo;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  text-decoration: none;
  color: var(--color-button-text);
  background: var(--color-primary-lighter);

  transition: background-color 0.2s;
}

.userDialog .controls a.btnCancelar {
  background: #dc3545;
}

.userDialog .controls a:first-child {
  margin-right: 1.6rem;
}

.userDialog .controls a:hover {
  background: var(--color-primary-dark);
}

.userDialog .controls a.btnCancelar:hover {
  background: #c82333;
}

@media (min-width: 700px) {
  :root {
    font-size: 62.5%;
  }
}
