#page-operador {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template:
    'sidenav header' 7.5rem
    'sidenav content' 1fr
    / 30rem 1fr;
}

#page-operador .bloco {
  display: revert;
  /* width: 100%; */
  align-items: left;
  background: var(--color-box-base);
  margin: 1rem;
  border-radius: 0.8rem;
  padding: 1rem;
}

#page-operador .bloco-input {
  display: inline;
  width: 100%;
  align-items: left;
  background-color: red;
  /* background: var(--color-box-base); */
  margin: 1rem;
  border-radius: 0.8rem;
  padding: 1rem;
}

#page-operador .content {
  /* background: var(--color-box-base); */
  padding: 1rem;
  /* width: 100vw; */
  border-radius: 0.8rem;
}

#page-operador .buttons-container {
  display: flex;
  justify-content: center;
  /* margin: 3.2rem 0; */
}

#page-operador .resultado {
  padding: 1rem;
  overflow: auto;
  height: 20rem;
  width: 100%;
}

#page-operador .resultado :hover {
  background: var(--color-background);
}

#page-operador .titulo-grupo {
  margin-top: 25px;
}

#page-operador .nao-vincular {
  background-color: var(--color-background);
}

#page-operador .buttons-grid.nao-vincular a {
  background-color: var(--color-background);
  display: none;
}

#page-operador .buttons-container a {
  /* min-width: 8rem;
  height: 4rem; */
  cursor: pointer;
  border-radius: 0.8rem;
  font: 700 1.6rem Archivo;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  padding: 1rem;
  text-decoration: none;
  color: var(--color-button-text);

  background: var(--color-primary-lighter);
  transition: background-color 0.4s;
}

#page-operador .buttons-container a:first-child {
  margin-right: 1.6rem;
}

#page-operador .buttons-container.commargem {
  margin: 1.6rem;
}

#page-operador .buttons-container a:hover {
  background: var(--color-primary-dark);
}

.svg-inline--fa {
  margin-right: 1rem;
  margin-left: 1rem;
}

#page-operador .buttons-grid a:hover {
  background: var(--color-primary-dark);
}

#page-operador .grid {
  display: grid;
  grid-template-columns: 20% 20% 60%;
}

#page-operador .content.left {
  justify-self: left;
  margin: 5px;
}

#page-operador .filters {
  display: flex;
  align-items: left;
  justify-content: left;
  background: var(--color-box-base);
  /* height: 5.8rem; */
  padding: 12px;
  border-radius: 0.8rem;
  width: 83vw;
  margin: 1rem;
}

#page-operador #filtro {
  height: 3.8rem;
  margin-left: 2rem;
  width: 35rem;
}

#page-operador .controls {
  margin-top: 1rem;
  border-top: 1px solid #cccccc;
  /* width: 100%; */
  padding: 2rem;
  background: var(--color-box-base);
}

#page-operador .file-block {
  margin-top: 1.6rem;
}

#page-operador .bloqueado {
  display: none;
}

#page-operador .avatar .image {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border: 0.1rem solid black;
  border-radius: 100%;
  overflow: hidden;
  width: 120px;
  height: 120px;
}

#page-operador .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 7.5rem;
}

#page-operador .avatarLista {
  /* border-radius: 100%; */
  overflow: hidden;
  width: 50px;
  height: 50px;
}

#page-operador .avatarLista img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

@media (max-width: 1400px) {
  #page-operador {
    grid-template:
      'sidenav header' 5rem
      'sidenav content' 1fr
      / 30rem 1fr;
  }
}
