#modal-x {
}

#modal-x .controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  justify-content: center;
}

#modal-x .controls a.cancel {
  background: #dc3545;
}

#modal-x .controls a:first-child {
  margin-right: 1.6rem;
}

/* #modal-x .controls a {
  width: 11rem;
  height: 4rem;
  cursor: pointer;
  border-radius: 0.8rem;
  font: 700 1.6rem Archivo;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  text-decoration: none;
  color: var(--color-button-text);

  transition: background-color 0.2s;
} */

/* #modal-x .controls a {
  background: var(--color-primary-lighter);
} */

/* #modal-x .controls a:hover {
  background: var(--color-primary-dark);
} */

#modal-x .controls a.cancel:hover {
  background: #c82333;
}
