#page-password-reset {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;

  align-items: center;

  padding-top: 2rem;

  /* color: var(--color-text-in-primary); */
  background: linear-gradient(
      180deg,
      var(--color-primary) 0%,
      var(--color-secondary) 100%
    ),
    rgba(197, 197, 197, 0.55);
  /* background: var(--color-primary); */
}

#page-password-reset .password-reset-box {
  background: var(--color-box-base);
  width: 58.4rem;
  height: 35rem;
  border-radius: 0.8rem;
}

#page-password-reset .password-reset-box-body {
  padding: 2rem;
}

#page-password-reset .password-reset-box-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

#page-password-reset .password-reset-box-header img {
  width: 20rem;
}
#page-password-reset .password-reset-box-header p {
  font: 700 2.9rem Poppins;
  margin-bottom: 2rem;
}

#page-password-reset .input-block + .input-block {
  margin: 0rem;
}

#page-password-reset .input-block input {
  margin-top: 0rem;
}

#page-password-reset .input-block:nth-child(1) input {
  border-radius: 0.8rem 0.8rem 0 0;
}

#page-password-reset .input-block:nth-child(2) input {
  border-top: 0;
  border-radius: 0 0 0.8rem 0.8rem;
}

.password-reset-box .password-reset-box-footer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.password-reset-box .password-reset-box-footer .controls {
  /* font: 500 1.4rem Poppins;
  width: 100%;
  padding: 0rem 6rem;
  display: flex;
  justify-content: space-between;
  margin: 3rem 0; */
}

.password-reset-box .password-reset-box-footer .controls label span {
  margin-bottom: 1rem;
}

.password-reset-box .password-reset-box-footer button {
  font: 700 1.6rem Archivo;
  width: 35.2rem;
  height: 5.6rem;
  border: none;
  cursor: pointer;
  background-color: var(--color-secondary);
  border-radius: 0.8rem;
  color: var(--color-title-in-primary);
}
