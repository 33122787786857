#page-cliente {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template:
    'sidenav header' 7.5rem
    'sidenav content' 1fr
    / 30rem 1fr;
}

#page-cliente .button-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

#page-cliente .redButton {
  background-color: red;
}

#page-cliente .add-veiculo {
  display: flex;
  justify-content: flex-end;
}

#page-cliente .subtitle {
  margin-top: 1rem;
}

#page-cliente hr {
  border: 1px solid var(--color-text-complement);
  opacity: 0.3;
}

#page-cliente .input-block.logradouro {
  width: 100%;
}

.table-clientes .statusCliente {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  border-radius: 1rem;
}

.addCarFields .controls {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 3.4rem;
}

.table-clientes .statusCliente.clienteAtivo {
  background-color: green;
}

.table-clientes .statusCliente.clienteInativo {
  background-color: #a1002e;
}

.table-clientes .statusCliente span {
  color: white;
}

.addCarFields {
  margin-bottom: 4rem;
}

@media (max-width: 1400px) {
  #page-cliente {
    grid-template:
      'sidenav header' 5rem
      'sidenav content' 1fr
      / 30rem 1fr;
  }
}
